/**
 * HTML Templates for use in client-side rendering.
 *
 * @module dom.templates
 * @version 1.0.0
 */

'use strict';

/**
 * QuickView Templates
 */
exports.quickView = {
    container: '<div class="quickview-container col-12 hidden-md-down"></div>',
    quickViewModal: `
        <div class="modal fade" id="quickViewModal" role="dialog" tabindex="-1">
            <span class="enter-message sr-only"></span>
            <div class="modal-dialog modal-dialog-centered quick-view-dialog">
                <div class="modal-content">
                    <div class="modal-body"></div>
                </div>
            </div>
        </div>
    `
};

/**
 * Get All Wishlist Collection Templates
 */
exports.addToWishlist = {
    container: '<div class="addToWishlist-container col-12 hidden-md-down"></div>',
    addToWishlistModal: `
    <div class="modal fade add-product-to-wishlist-dialog" id="addToWishlistModal" tabindex="-1" role="dialog">
            <span class="enter-message sr-only"></span>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="generic-confirmation-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <svg role="img" aria-describedby="add-to-wishlist-cross-title add-to-wishlist-cross-desc" class="icon svg-icon svg-logo-dims" width="16.0" height="16.0">
                                <title id="add-to-wishlist-cross-title">Close dialog</title>
                                <desc id="add-to-wishlist-cross-desc">Cross mark icon to close this dialog</desc>
                                <use xlink:href="#cross" role="img"></use>
                            </svg>
                        </button>
                    </div>
                    <div class="modal-body"></div>
                </div>
            </div>
    </div>
    <isinclude template="wishlist/components/createWishlist" />

    `
};

/**
 * Cart error message template
 */
exports.cart = {
    errorMessage: '<p class="cart-error-message">{0}</p>',
    cartAvailabilityMessageOOS: (message, url) => (`
        <li><p class="availability-message-text" role="alert">${message}
            <a href="${url}" class="similar-styles-category-link">Browse Similar Styles</a></p>
        </li>
    `),
};

/**
 * Cart & Checkout promo template
 */
exports.promo = {
    shippingMethod: `
        <div class="row leading-lines shipping-item">
            <div class="col-9 start-lines">
                <p class="order-receipt-label">
                    <span>{0}:<span class="shipping-method-name">{1}</span></span>
                </p>
                <p class="shipping-arrival">
                    <span class="shipping-arrival-time">{3}</span>
                </p>
            </div>
            <div class="col-3 end-lines">
                <p class="text-right">
                    <span class="shipping-total-cost">{2}</span>
                </p>
            </div>
        </div>`,
    checkoutNormalShipping: '<span class="text-right pricing shipping-method-price">{0}</span>',
    checkoutDiscountShipping: `
        <del class="text-right pricing shipping-method-price">{0}</del>
        <span>{1}</span>
    `
};

/**
 * Checkout template
 */
exports.checkout = {
    discountPrice: '<del class="shipping-cost">{0}</del><span>{1}</span>'
};

/**
 * Selected swatch name template
 */
exports.swatchNameTemplate = {
    swatchNameHtml: '<p class="selected-swatch-name">Color: {0}</p>'
};

exports.emptySwatchTemplate = '<span class="swatches-empty">{0}</span>';

/*
 * Address Suggestions template
 */
exports.addressSuggestions = {
    suggestionTextTemplate: '<p class="address-suggestion-text"></p>',
    suggestionsTemplate: `
        <div id="address-suggestions-template">
            <div class="form-check custom-radio">
            <input type="radio" class="form-check-input" id="addressSuggestion" name="addressSuggestion">
            <label class="form-check-label shipping-address-option" for="addressSuggestion">
                <span class="address-suggestion"></span>
            </label>
            </div>
        </div>`
};

/*
 * Approaching Discounts template
 */
exports.approachingDiscounts = {
    discountContainerTemplate: '<ul class="approaching-discounts"></ul>',
    singleDiscountTemplate: '<li class="single-approaching-discount">{0}</li>',
    multipleDiscountsTemplate: `
        <li id="cart-approaching-discounts-accordion" class="accordion-container">
            <div class="card">
                <div id="cart-approaching-discounts-collapse" class="collapse"
                    aria-labelledby="cart-approaching-discounts-heading" data-parent="#cart-approaching-discounts-accordion">
                    <ul class="card-body">{0}</ul>
                </div>
                <div class="card-header" id="cart-approaching-discounts-heading">
                    <h5 class="mb-0 text-center">
                        <button class="btn btn-link show-more-less-button collapsed" data-toggle="collapse"
                            data-target="#cart-approaching-discounts-collapse" aria-expanded="false"
                            aria-controls="cart-approaching-discounts-collapse">
                            <span class="collapsed">{1}</span>
                            <span class="expanded">{2}</span>
                        </button>
                    </h5>
                </div>
            </div>
        </li>`,
    nonCouponBasedAdjustmentTemplate: `<div class="price-container leading-lines">
        <div class="flex justify-content-between align-items-center">
            <p class="price-text">{0}
                <span class="promo-description non-coupon-price">{2}</span>
            </p>
            <p class="end-lines price-value">
            <span>{1}</span>
            </p>
        </div>
    </div>`
};

/**
 * Video Template
 * @param  {Object} options video setting values
 * @return {html} video html template
 */
exports.videoTemplate = (options = {}) => {
    // Note: I don't believe this template is used anywhere anymore
    const { loop = '', videoUrl = '', imgUrl = '', classNames = '', autoplay = false, muted = true, controls = false, icontitle = '', icondesc = '' } = options;

    return `
        <div class="video-component ${classNames}">
            <video class="video" playsinline ${autoplay ? 'autoplay' : ''} ${loop ? 'loop' : ''}  ${muted ? 'muted' : ''} poster="${imgUrl} ${controls ? 'controls' : ''}>
                <source src="${videoUrl}" type="video/mp4" />
            </video>
            <button class="play-video">
                <svg role="img" class="icon svg-icon svg-video-play" width="16" height="16" aria-labelledby="title" aria-describedby="desc">
                    <title>${icontitle}</title>
                    <desc>${icondesc}</desc>
                    <use xlink:href="#play" role="img"></use>
                </svg>
            </button>
        </div>
    `;
};

/**
 * Image Template
 * @param  {string} imgUrl value
 * @param  {string} altText value
 * @param  {string} classNames value
 * @return {html} video html template
 */
exports.imageTemplate = (imgUrl, altText, classNames) => {
    return `
        <div class="image-component ${classNames}">
            <img class="w-100" src="${imgUrl}" alt="${altText}"/>
        </div>
    `;
};

/**
 * Video Thumbnail Template on PDP
 * @param {string} carouselId - Carousel id value
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {string} videoUrl - Selected variant group video url
 * @param {string} videoThumbnailImage - Selected variant group poster url
 * @return {html} List item html template
 */
exports.videoThumbnailTemplate = (carouselId, imgs, videoUrl, videoThumbnailImage) => {
    return `
        <li data-target="#${carouselId}" 
            data-slide-to="${imgs.length}" 
            class="video-thumbnail">
            <a href="${videoUrl}" class="mz-thumb-selected">
                ${videoThumbnailImage ? '<img src="' + videoThumbnailImage + '" class="img-fluid" alt="' + imgs[0].alt + ' video" itemprop="image" />' : '<video playsinline controlslist="nodownload"><source type="video/mp4" src="' + videoUrl + '"></video>'}
                <svg role="img" 
                    aria-describedby="play-title play-desc" 
                    class="icon svg-icon svg-video-play" 
                    width="40" 
                    height="40">
                    <title id="play-title">Play</title>
                    <use xlink:href="#play" 
                        role="img" 
                        title="Play"></use>
                </svg>
            </a>
        </li>
    `;
};

/**
 * Size option template
 * @param  {string} url - Size variant url
 * @param  {string} value - Size value
 * @return {html} size option html template
 */
exports.sizeOptionTemplate = (url, value) => {
    return `<option value="${url}" data-attr-value="${value}">${value}</option>`;
};

/**
 * Function to rerender size button element
 * @param {Object} variantData - variant details
 * @param {string} ariaLabel - aria label
 * @param {string} ariaDescribedby - aria described by
 * @param {string} selectedText - selected text
 * @param {string} oosMessage - out of the stock text
 * @return {html} size button html template
 */
exports.sizeButtonTemplate = (variantData, ariaLabel, ariaDescribedby, selectedText, oosMessage) => {
    const { selected, forceOutOfStock, inStock, value, url, sizeSwatchNotAvailable } = variantData;
    return `<li class="size-list ${sizeSwatchNotAvailable ? 'd-none' : ''}">
                <button class="btn size-btn ${selected ? 'selected' : ''} ${!inStock || forceOutOfStock ? ' not-available' : ''}" data-attr-url="${url ||
        ''}" data-attr-value="${value}" data-value-id="${value}" aria-label="${ariaLabel}" aria-describedby="${ariaDescribedby}">${value}</button>
                <span id="${ariaDescribedby}" class="sr-only selected-assistive-text" data-selected-text="${selectedText}" data-out-of-stock="${oosMessage}">${selected ? selectedText : ''} ${!inStock || forceOutOfStock ? oosMessage : ''}</span>
            </li>`;
};

/**
 * Function to render empty wishlist markup for guest user
 * @param {string} emptyWishlistMessage - empty wishlist message
 * @return {html} wishlist html template
 */
exports.guestEmptyWishlistTemplate = (emptyWishlistMessage, emptyWishlistContentAssetBody) => {
    return `<div class="col-12 col-md-6 empty-wishlist">
                <div class="empty-list-user-section">
                    <p class="non-logged-user-message">${emptyWishlistMessage}</p>
                    ${emptyWishlistContentAssetBody}
                </div>
            </div>`;
};

/**
 * Function to render empty wishlist markup for guest user
 * @param {string} emptySFLMessage - empty SFL message
 * @param {string} continueShopURL - continue shopping URL
 * @param {string} continueShopMsg - continue shopping message
 * @param {string} signInMsg - sign In message
 * @param {string} signInSFLURL - sign in URL
 * @return {html} SFL html template
 */
exports.emptySFL = (emptySFLMessage, continueShopURL, continueShopMsg, signInMsg, signInSFLURL) => {
    return `<div class="sfl-cart-products">
                <div class="container cart-empty sfl-empty">
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="sfl-empty-msg empty-message">${emptySFLMessage}</p>
                            <a href="${signInSFLURL}"
                                class="btn btn-block button-secondary account-login-btn enable-button-onvalidate sfl-login-btn col-md-6">${signInMsg}
                            </a>
                            <a href="${continueShopURL}" class="d-inline-block tertiary-link continue-shop-link" role="link">${continueShopMsg}</a></div>
                        </div>
                    </div>
                </div>
            </div>`;
};

/**
 * GWP Site Templates
 */
exports.gwpSizeTemplate = {
    singleSize: '<span class="selected-size">{0}</span>',
    multiSize: `
        <div class="size-list">
            <span class="sr-only selected-assistive-text"></span>
            <select class="custom-select form-control select-size" id="{0}">
                {1}
            </select>
        </div>
    `,
    multiDefaultSizeOption: '<option value="{0}">{1}</option>',
    multiSizeOption: '<option value="{0}" data-attr-value="{1}" class="{4}" {2} {5}>{3}</option>'
};

/**
 * Address autocomplete templates
 */
exports.autoComplete = {
    suggestionLink: (suggestion, addressLine, secondaryEntryAddressLine) => (`
        <a href="javascript:void(0);"
            class="autocomplete-suggestion list-group-item"
            data-address-one="${suggestion.street_line}"
            data-address-two="${suggestion.secondary || ''}"
            data-state="${suggestion.state}"
            data-city="${suggestion.city}"
            data-zipcode="${suggestion.zipcode}"
            data-has-secondary-entries="${suggestion.entries > 1}"
            data-secondary-entry-search-value="${secondaryEntryAddressLine}"
        >${addressLine}</a>
    `),
    suggestionContainer: containerClass => (`<div class="autocomplete-suggestions list-group ${containerClass || ''}"></div>`)
};

exports.contactUsTemplate = {
    signupMessage: (status, msg) => (`
        <div class="contact-us-signup-alert text-center ${status}" role="alert">
            ${msg}
        </div>
    `)
};

exports.browserWarningTemplate = `
    We're sorry, this browser is no longer supported.<br/>
    Please update your browser for a smoother, more secure shopping experience. We recommend downloading the latest version of
    <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank" rel="nofollow">Google Chrome</a>,
    <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="nofollow">Microsoft Edge</a> or
    <a href="https://www.mozilla.org/en-US/firefox/desktop/" target="_blank" rel="nofollow">Mozilla Firefox</a>.
`;

/*
 * Shipping preference radio buttons
 */
exports.shippingPreferenceTemplates = {
    shippingPreferences: (ispuRadioLabel, ispuRadioValue, shipToRadioLabel, shipToRadioValue, changeStoreLabel, preferISPU = false) => (`
        <div class="form-check custom-control custom-checkbox preference-container ispu-preference-container">
            <input id="preference-ispu" name="shipping-preference" type="checkbox" class="custom-control-input shipping-preference preference-ispu" ${preferISPU ? 'checked' : ''} value="${ispuRadioValue}">
            <label class="custom-control-label" for="preference-ispu">${ispuRadioLabel}</label>
            <button class="btn-link tertiary-link change-store-link">${changeStoreLabel}</button>
            <div class="content">
                <div class="select-size-info-msg"></div>
                <div class="ispu-info-message"></div>
                <div class="message-wrapper">
                    <div class="inventory-message"></div>
                    <div class="ispu-delivery-estimation-message"></div>
                </div>
                <div class="service-info-message"></div>
                <div class="store-detail-container"></div>
            </div>
        </div>
        <div class="form-check custom-radio preference-container ship-to-address-preference-container d-none">
            <input id="preference-ship-to-address" name="shipping-preference" type="radio" class="form-check-input shipping-preference preference-ship-to-address" value="${shipToRadioValue}" ${!preferISPU ? 'checked' : ''}>
            <label class="form-check-label" for="preference-ship-to-address">${shipToRadioLabel}</label>
            <div class="content">
                <div class="narvar-edd-info"></div>
                <div class="ship-to-info-message"></div>
            </div>
        </div>
    `),
    storeDetail: (storeId, name, address1, city, stateCode, postalCode) => (`
        <div class="store-detail">
            <div class="name" data-store-id="${storeId}">${name}</div>
            <div class="address">
                <div class="address-line-1">${address1}<span class="comma-separate">,</span>
                    <span class="city-state-postalcode"> ${city}<span class="city-comma-separate">,</span> ${stateCode} ${postalCode}</span>
                </div>
            </div>
        </div>
    `)
};

/*
 * Product detail page - messaging templates
 */
exports.productDetail = {
    selectStyleMessageTmpl: message => `<li><p class="availability-message-text select-size-info" role="alert">${message}</p></li>`,
    availabilityMessageTmpl: message => `<li><p class="availability-message-text" role="alert">${message}</p></li>`,
    restrictedMessageTmpl: message => `<li><p class="availability-message-text mt-2" role="alert">${message}</p></li>`,
    availabilityMessageOOS: (message, url) => (`
        <li><p class="availability-message-text" role="alert">${message}
            <a href="${url}" class="similar-styles-category-link">Browse Similar Styles</a></p>
        </li>
    `),
    availabilityMessagePreorder: (message, url) => (`
        <li><div class="availability-message-text-preorder" role="alert">
            <p class="message">${message}</p>
            <a href="${url}" class="similar-styles-category-link-preorder">Shop for Similar Styles</a></div>
        </li>
    `),
    ispuSelectStyleMessageTmpl: message => `<p class="availability-message-text" role="alert">${message}</p>`,
    ispuAvailabilityMessageTmpl: message => `<p class="availability-message-text" role="alert">${message}</p>`,
    ispuLowStockMessageTmpl: message => `<li><p class="ispu-availability-message-text" role="alert">${message}</p></li>`
};

exports.dialogTemplate = options => {
    const {
        buttons = [{ text: 'OK', primary: true, link: '' }],
        className = '',
        modalContentHeading = '',
        modalContentBody = '',
        id = 'dialog-' + Date.now(),
        slideOut = false
    } = options;

    const buttonSpace = Math.floor(12 / buttons.length);

    return `
        <div class="modal fade ${slideOut ? 'lp-slideout-modal' : 'generic-modal-dialog'} ${className}" id="${id}" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content${slideOut ? ' lp-scrollbar' : ''}">
                    <div class="${slideOut ? 'modal-header' : 'generic-confirmation-header'}">
                        ${slideOut && modalContentHeading ? `<h4>${modalContentHeading}</h4>` : ''}
                        <button type="button" class="close close-current-modal" data-dismiss="modal" aria-label="Close">
                            <svg role="img" aria-describedby="${id}-cross-title ${id}-cross-desc" class="icon svg-icon svg-logo-dims" width="16.0" height="16.0">
                                <title id="${id}-cross-title">Close dialog</title>
                                <desc id="${id}-cross-desc">Cross mark icon to close this dialog</desc>
                                <use xlink:href="#cross" role="img"></use>
                            </svg>
                        </button>
                    </div>
                    ${!slideOut && modalContentHeading ? `<p class="modal-body generic-confirmation-body-heading">${modalContentHeading}</p>` : ''}
                    <div class="modal-body generic-confirmation-body-body${slideOut ? ' veiled' : ''}">${modalContentBody}</div>
                    <div class="row">
                        ${buttons.map(button => `
                            <div class="col-${buttonSpace}">
                            ${button.link ?
            `<a href="${button.link}" class="btn btn-block button-${button.primary ? 'primary' : 'secondary'}">${button.text}</a>` :
            `<button type="button" class="btn btn-block button-${button.primary ? 'primary' : 'secondary'}" data-dismiss="modal">${button.text}</button>`
        }
                            </div>`).join('')
        }
                    </div>
                </div>
            </div>
        </div>
    `;
};

exports.errorTextTemplate = errorMessage => `<div class="container"><p class="error-message-text">${errorMessage}</p></div>`;

exports.oneTrust = {
    privacyDialogCookieBanner: `
        <div class="onetrust-dialog-cookie-banner">
            <div class="onetrust-dialog-cookie-banner-text">
                You can opt out of 3rd party cookies by clicking <button class="cookie-preferences">Cookie Preferences</button>.
            </div>
        </div>
    `
};
exports.loyalty = {
    carouselBadge: '<div class="loyalty-product-pdp-badge product-tile-badge align-items-center justify-content-center d-none"><span class="loyalty-product-pdp-badge-text product-tile-badge-text">{0}</span></div>'
};
